import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, map } from 'rxjs';

import { amazon, environment } from '@envs/environment';
import { RefreshTokenResponse } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor( private http: HttpClient, private jwtHelperService: JwtHelperService ) { }

  refreshToken(): Observable<string> {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_refreshToken,
    });
    return this.http.post<RefreshTokenResponse>(
      `${ amazon.apiUrlAmazon }/${environment.ambiente}/refreshToken`,
      {
        numeroCelular: sessionStorage.getItem('numeroCelular'),
        token: sessionStorage.getItem('tokenTelcel')
      },
      { headers }
    ).pipe(
      map(( response ) => {
        return response.responseObject.refreshToken;
      })
    )
  }

  isValidToken(): boolean {
    const tokenTelcel = sessionStorage.getItem('tokenTelcel');
    if (tokenTelcel == '' || tokenTelcel == null || tokenTelcel == 'tokenTelcel is null') {
        return false;
    }
    const isTokenExpired = this.jwtHelperService.isTokenExpired(tokenTelcel);
    if(isTokenExpired == true) {
        return false;
    }
    return true;
  }
}
