import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';

import { amazon, environment } from '@envs/environment';

interface ParamsUrl {
 // fzavta: string|null;
  idAgente: string|null;
  //canal: string|null;
}


@Injectable({
  providedIn: 'root'
})
export class ValidUrlService {
  readonly url: string = environment.ambiente;
  private readonly http = inject(HttpClient);


  validUrl(data: ParamsUrl): Observable<any> {

    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_ValidUrl
    });

    const req = `${amazon.apiUrlAmazon}/${this.url}/validarDatosUrl`
    return this.http.post<any>(req, data, { headers })
    .pipe(
      catchError( error => {
        const statusCode = error.status;
        switch (statusCode) {
          case 401:
            return throwError(() => 'Acceso no autorizado');
          case 404:
            return throwError(() => error.error);
          case 500:
            return throwError(() => 'Error de servidor');
          default:
            break;
        }
        return throwError(() => error.statusText)
      })
    );
  }
}
