import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';

import { amazon, environment } from '@envs/environment';
import { manejarError } from '../helpers';
import { OTPRequest, OtpResponse, PayloadOTPVerificacion } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class OtpService {

  constructor( private http: HttpClient ) { }

  sendOTPVerificacion({ correo, ...rest }: PayloadOTPVerificacion): Observable<OtpResponse> {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_sendOTPVerificacion
    });

    const request: OTPRequest = this.crearRequestOTPVerificacion({ correo, ...rest });

    return this.http.post<OtpResponse>(
      `${ amazon.apiUrlAmazon }/${environment.ambiente}/sendOTPVerificacion`,
      request,
      { headers }
    ).pipe(
      map((response) => {
        if ( !response.process ) throw response.message;
        return response;
      }),
      catchError((error) => manejarError(error))
    )
  }

  returnProcess({ correo, ...rest }: any): Observable<any> {
    const parsedOBJ: OTPRequest = {
      numeroCelular : sessionStorage.getItem('numeroCelular')!,
      token: sessionStorage.getItem('tokenTelcel')!,
      payload: {
        ...rest,
        correo: correo || sessionStorage.getItem('emailIngresado') || ''
      }
    }

    const headers = new HttpHeaders({
        'x-api-key': environment.apiKey_retomarTramiteSms,
        'jwt': sessionStorage.getItem('tokenTelcel')!
    });

    return this.http.post<OtpResponse>(
      `${ amazon.apiUrlAmazon }/${environment.ambiente}/retomarTramiteSms`,
      parsedOBJ,
      { headers }
    ).pipe(
      map((response) => {
        if ( !response.process ) throw response.message;
        return response;
      }),
      catchError((error) => {
        return manejarError(error);
      })
    );
  }

  private crearRequestOTPVerificacion({ correo, ...rest }: PayloadOTPVerificacion): OTPRequest {
    return {
      numeroCelular: sessionStorage.getItem('numeroCelular')!,
      token: sessionStorage.getItem('tokenTelcel')!,
      payload: {
        ...rest,
        correo
      }
    }
  }
}
